@import '../../../variables';

.userName,
.userRole,
.inputName {
  margin-bottom: 0;
}

.userName {
  text-transform: capitalize;
  color: $text-menu-800;
}

.userRole {
  line-height: 10px;
  font-size: 12px;
  color: $text-menu-600;
}

.avatar {
  background-color: $orange-1;
  color: $orange-7;
  min-width: 32px;
  margin-left: 20px;
}

.link {
  text-decoration: none;
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.drawerInfoWrapper {
  margin-top: 20px;
  padding: 20px;
}

.drawerBgImage {
  width: 100%;
  height: 150px;
  min-height: 105px;
  object-fit: cover;
  background: linear-gradient(to bottom right, #aaa89b, #c1b18d);
}

.drawerUserInfoWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.drawerTagRole {
    margin-top: 10px;
  }

.drawerAvatar {
  @extend .avatar;
  position: relative;
  cursor: initial;
  bottom: 30px;
  margin-left: 0;
}

.drawerInputWrapper {
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }
}

.drawerSaveButton {
  width: 100%;
}
