@import "../../../variables";
$bg-card-container: $txt-primary-color;
$txt-color-banner: $bg-primary-color;

.wrapper {
  text-align: center;
  padding: 0 0 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.textInfo {
  text-align: left;
  font-size: 26px;
  width: fit-content;
  color: $txt-color-banner;
  letter-spacing: 1px;
  font-weight: 700;
}

.cardContainer {
  background-color: $bg-card-container;
  padding: 0px;
  border-radius: 0  0 10px 10px;
}

.shape {
  position: relative;
  top: -10px;
  left: -12px;
}

.box {
  display: flex;
  background-color: $bg-card-container;
  padding: 0px;
  border-radius: 0  0 10px 10px;
}
