@import '../../variables';
@import '../../breakpoints';

.header,
.wrapper,
.drawerUserInfoWrapper {
  display: flex;
}

.initiativeName,
.bell {
  color: $blue-7;
}

.header {
  align-items: center;
  justify-content: space-between;
  background-color: $neutral-5 !important;
  min-height: 65px;
  max-height: 65px;
}

.link {
  text-decoration: none;
}

.controlCenterLogoWrapper {
  display: flex;
  align-items: center;

  .buttonSidebarCollapse {
    border: none;
    background: transparent;
    color: $blue-7;
    font-size: 20px;
  }

  .companyLogo {
    width: 160px;
  }
}

.wrapper {
  align-items: center;
  width: fit-content;
}

.bell,
.avatar {
  cursor: pointer;
}

.bell {
  font-size: 1.4em;
  margin-right: 10px;
}

.initiativeName {
  font-size: 17px;
  font-weight: $font-weight-1;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  transition: width 0.3s ease;
}

.avatar {
  background-color: $blue-7;
  color: #ffffff;
  min-width: 32px;
  margin-left: 15px;
}


@media screen and (max-width: $breakpoint-lg) {
  .initiativeName {
    font-size: 16px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .initiativeName {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .companyLogo,
  .sideBar {
    display: none;
  }

  .header {
    padding-inline: 20px !important;
  }

  .controlCenterLogoWrapper {
    .buttonSidebarCollapse {
      margin-left: 0;
    }
  }

  .avatar {
    margin-left: 10px;
  }
}
