@import '../../variables';
@import '../../Common.module.scss';

$bg-mainContent-color: $bg-primary-color;

.main {
  padding: 7%;
  margin: 0px;
  height: 100vh;
  background-color: $bg-mainContent-color;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
