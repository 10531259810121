@import "../../variables";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
// Your variable overrides
$header-bg-color: $txt-primary-color;
$tittle-color: $txt-quaternary-color;

* {
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  color: $txt-primary-color;
}

body > iframe {
  pointer-events: none;
}

.accounts {
  background: white;
  min-height: auto;

  .header {
    background: $header-bg-color;
    margin-top: -30px;
    padding-top: 30px;
    padding-left: 2rem;
    background-color: $header-bg-color;
    justify-content: space-between;
    align-items: baseline;
    display: flex;
  }
  h3 {
    font-size: 26px;
    padding-bottom: 20px;
    color: $tittle-color;
    margin-bottom:0px;
    font-weight:600
  }
  .searchContainer {
    padding: 20px 0px;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .logo{
    height: 70px;
    width: auto;
    margin:20px 40px 0px 0px;
  }

  .content {
    padding: 25px;
  }
}

.externalLink {
  padding: 10px 20px;

  .link {
      font-weight: bold;
      color: white;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      width: 100%;
  }
}
