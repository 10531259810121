@import '../../variables';

$title-color: $txt-quaternary-color;
$content-color: $txt-primary-color;
$fs-title-content: $fs-subtitle-primary;

.wrapper {
    .title, .content { font-size: $fs-title-content; }
    .title {
        color: $title-color;
        text-transform: capitalize;
        font-weight: 600;
    }

    .content {
        color: $content-color;
    }
}