@import '../../../variables';
@import '../../../breakpoints';
@import '../../../base';

.headerButton,
.headerWrapper,
.buttonsHeaderWrapper {
  display: flex;
  align-items: center;
}

.headerButton,
.addNewAttribute {
  border-color: transparent;
  background-color: transparent;
}

.headerWrapper {
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 30px;

  h4 {
    margin-bottom: 0;
  }
}

.headerButton {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: $shadow-1;
}

.headerButtonSkeleton {
  @extend .headerButton;
  background: linear-gradient(90deg, $bg-septenary-color 8%, $bg-primary-color 18%, $bg-septenary-color 33%);
  background-size: 200% 100%;
  animation: skeleton 1.6s linear infinite;
}

.addNewAttribute,
.activeButton {
  color: $txt-link-color;
}

.addNewAttribute {
  text-decoration: underline;

  span:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .headerWrapper {
    h4 {
      font-size: 15px;
      text-align: left;
    }
  }

  .addNewAttribute {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: $shadow-1;
    color: inherit;

    span:nth-child(1) {
      display: none;
    }

    span:nth-child(2) {
      display: block;
    }
  }
}
