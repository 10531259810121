@import '../../variables';

$bg-notification-color: $bg-senary-color;

$txt-tab-color: $bg-primary-color;
$bg-tab-selected-color: $primary-highlight-800;

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $txt-tab-color;
    text-decoration: none;

    .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: $fs-subtitle-secondary;
    }

    .notification {
        margin-left: 5px;
        background-color: $bg-notification-color;
        color: white;
        display: flex;
        height: 18px;
        width: 18px;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        font-size: 12px;
    }
}


.react-tabs {
    -webkit-tap-highlight-color: transparent;

    display: inline-block;
    border: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 15px 20px;
    background: rgba($bg-tab-selected-color, 0.5);
    color: $txt-tab-color;
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    margin-right: 4px;
    min-width: 94px;
  
    &--selected {
        background: $bg-tab-selected-color;
        border-color: $bg-tab-selected-color;
        border: 1px solid $bg-tab-selected-color;
    }

    &--selected:before {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid $bg-tab-selected-color;
        bottom: -12px;
        content: "";
        height: 0;
        left: 50%;
        margin-left: -12px;
        position: absolute;
        width: 0;
    }
}
