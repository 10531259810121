@import "../../../../variables";
@import "../../../../Common.module.scss";

$background-container-color: $bg-quinary-color;
$background-first-category: $bg-secondary-color;
$background-third-category: $bg-octonary-color;
$background-four-category: $bg-nonary-color;
$background-five-category: $bg-tertiary-color;
$border-bottom-title: $border-primary-color;

.containerShortcuts {
  margin-top: 15px;
  padding: 1.5em;
  border-radius: 8px;
  box-sizing: border-box;
  padding-bottom: 85px;
  
}

.dashboardTitle {
  padding: 10px 30px;
  box-shadow: 0 2px 8px -4px #ccc;
}

.wrap {
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.2);
}

.item-a {
  grid-column: 1;
  grid-row: 1;
  background-color: $background-first-category;
  svg {
    font-size:42px !important
  }
}
.item-b {
  grid-column: 4;
  grid-row: 1;
  background-color: $background-first-category;
}

.item-c {
  grid-column: 1;
  grid-row: 2;
  background-color: $background-four-category;
}

.item-d {
  grid-column: 2;
  grid-row: 2;
  background-color: $background-four-category;
}

.item-e {
  grid-column: 1;
  grid-row: 3;
  background-color: $background-third-category;
}

.item-f {
  grid-column: 2;
  grid-row: 3;
  background-color: $background-third-category;
}

.item-h {
  grid-column: 3;
  grid-row: 3;
  background-color: $background-third-category;
}

.item-i {
  grid-column: 4;
  grid-row: 3;
  background-color: $background-third-category;
}

.item-j {
  grid-column: 5;
  grid-row: 3;
  background-color: $background-third-category;
}
.item-k {
  grid-column: 1;
  grid-row: 4;
  background-color: $background-five-category;
}

.title {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 1.8rem
}

.subtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}

.box {
  display: grid;
  align-items: center;
  grid-template-columns: 17% 17% 17% 17% 17%;
  grid-template-rows: auto auto auto auto;
  gap: 40px 40px;
  box-sizing: border-box;
  padding: 20px 30px;
  border-radius: 8px;
}

@media (max-width: 1023px) {
  .box {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1415px) {
  .box {
    grid-gap: 1.8vw;
    grid-template-columns: 18% 18% 18% 18% 18%;
  }
}
