@import '../../../variables';
@import '../../../breakpoints';

$height: 30px;
$width: 140px;

.clientLogoDiv {
  display: flex;
   color: $white-1;
}

.clientLogo,
.clientLogoDiv,
.clientLogoDark {
  margin-right: 10px;
}

.clientLogo,
.clientLogoDiv {
  width: $width;
  min-width: $width;
  max-width: $width;
  max-height: $height;
  min-height: $height;
}

.clientLogo {
  margin-left: 0px;
  object-fit: contain;
}

.clientLogoDark {
  background-color: #ffff;
  padding: 1px;
  border-radius: 4px;
}

.clientLogoDiv {
  align-items: center;
  justify-content: center;
}

.clientLogoSpan,
.clientLogoSpanError {
  line-height: 34px;
  padding: 0 15px;
  border-radius: 4px;
  width: 94px;
  text-align: center;
  background-color: $blue-7;
  cursor: pointer;
}

.clientLogoSpanError {
  background-color: #ff4d4f87;
}

@media screen and (max-width: $breakpoint-lg) {
  $width: 120px;

  .clientLogo,
  .clientLogoDiv {
    width: $width;
    min-width: $width;
    max-width: $width;
    max-height: $height;
    min-height: $height;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  $width: 100px;

  .companyLogo {
    display: none;
  }

  .header {
    padding-inline: 20px !important;
  }

  .controlCenterLogoWrapper {
    .buttonSidebarCollapse {
      margin-left: 0;
    }
  }

  .clientLogo,
  .clientLogoDiv {
    width: $width;
    min-width: $width;
    max-width: $width;
    max-height: $height;
    min-height: $height;
  }

  .avatar {
    margin-left: 10px;
  }
}
