@import "../../variables";
$bg-loading-color: #f9f9f9;
$txt-loading-color: $txt-primary-color;

.container {
    background: $bg-loading-color;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $txt-loading-color;
}

%base-loader {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loading 1.8s infinite ease-in-out;
    animation: loading 1.8s infinite ease-in-out;
}

.loader {
    @extend %base-loader;
    color: $txt-loading-color;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &:before, &:after {
        @extend %base-loader;
        content: '';
        position: absolute;
        top: 0;
    }

    &:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    &:after {
        left: 3.5em;
    }
}

@-webkit-keyframes loading {
    0%, 80%, 100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes loading {
    0%, 80%, 100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}