@import '../../variables';
@import '../../Common.module.scss';

.modal {
  background-color: $green-900 !important;
  border-radius: 12px !important;
  padding: 0 !important;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 520px;
  width: 100%;
}

.head {
  display: flex;
  padding: 3em;
  height: 40%;

  .text,
  .image {
    display: grid;
    place-content: center;
  }

  .svg {
    position: absolute;
    z-index: 5;
  }

  .svg:nth-child(1) {
    left: 70%;
    top: 0;
    transform: rotate(90deg);
  }

  .svg:nth-child(2) {
    top: 18%;
    left: 48%;
  }

  .text {
    position: relative;
    z-index: 10;
    width: 60%;

    h2 {
      font-weight: 700;
      color: $white-1;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 0;
    }

    p {
      color: $violet-900;
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 400;
      line-height: 34px;
    }
  }

  .image {
    position: relative;
    z-index: 10;
    width: 40%;

    img {
      position: relative;
      left: 30px;
      width: 200px;
      top: 20px;
    }
  }
}

.body {
  height: 60%;
  background-color: $white-1;
  padding: 3em 3em;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
  }

  .button {
    display: grid;
    place-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .head {
    padding: 1.5em;

    .text {
      h2,
      p {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  .body {
    padding: 1.5em 2em;

    p {
      font-size: 10px;
      line-height: 20px;
    }
  }
}
