.popoverButton {
    display: grid;
    border: none;
    background-color: transparent;
}

.popoverIcon {
    color: #D2D2DC;
    font-size: 26px;
}

.content {
    max-width: 500px;
}
