@import '../../overrides';

$buttonSize: 65px;

.button {
  position: fixed;
  display: grid;
  place-content: center;
  bottom: 10px;
  z-index: 1021;
  right: 19px;
  height: $buttonSize;
  width: $buttonSize;
  border-radius: 50%;
  border: none;
  overflow: hidden;
  background-color: $blue-7;
  animation: button 1s ease-in-out;

  &::after {
    display: block;
    position: absolute;
    bottom: -40px;
    right: -52px;
    border-radius: 50%;
    content: ' ';
    background-color: rgba(255, 255, 255, 0.294);
    height: $buttonSize;
    width: $buttonSize;
    transition: transform 0.5s ease-in-out;
  }

  &:hover::after {
    transform: translate(-$buttonSize, -$buttonSize) scale(2);
  }
}

.error {
  @extend .button;
  animation: shrinkElement 0.5s linear 5s forwards;
}

.loaderIcon,
.widgetIcons {
  color: $white-2;
}

.loaderIcon {
  font-size: 1em;
}

.widgetIcons {
  font-size: 2em;
}

@keyframes button {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrinkElement {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
