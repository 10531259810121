@import '../../../variables';
@import '../../../breakpoints';

// Your variable overrides
$sidebar-bg-color: $blue-7;
$submenu-bg-color: transparent;
$sidebar-color: $sidebar-color-default;
$sidebar-color-disabled: $sidebar-color-disabled;
$sidebar-item-bg-color-hover: $sidebar-color-box-hover;
$highlight-color: $sidebar-color-selected;
$highlight-bg-color: transparent;

.control-center-logo-wrapper {
  padding-top: 20px;
  max-width: 200px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.control-center-sider {
  color: $sidebar-color;
  &.ant-layout-sider.ant-layout-sider-light {
    background: $sidebar-bg-color;
  }
}

.ant-affix {
  .control-center-sider-menu.ant-menu-inline-collapsed {
    padding: 0px;
  }
}

.control-center-sider-menu {
  padding: 20px;
  color: $sidebar-color;
  overflow-y: auto;
  max-height: 100vh;
  a {
    text-decoration: none !important;
  }
  &.ant-menu.ant-menu-root.ant-menu-light {
    background: $sidebar-bg-color;
    border-right: 0px;
    overflow-x: hidden !important;

    .ant-menu-submenu-selected > .ant-menu-submenu-title,
    .ant-menu-submenu-selected
      > .ant-menu-submenu-title
      .ant-menu-item
      .ant-menu-item-selected,
    .ant-menu-submenu-selected
      > .ant-menu-submenu-title
      .ant-menu-item
      .ant-menu-item-selected
      a {
      color: $highlight-color !important;
    }
  }

  .ant-menu-item-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ant-menu-item,
  .ant-menu-item > .anticon {
    font-size: 18px;
    font-weight: bold;
    //color: $sidebar-color;
  }

  .ant-menu-item {
    &:hover {
      background-color: $sidebar-item-bg-color-hover !important;
      color: $sidebar-color !important;
    }

    &.ant-menu-item-selected {
      color: $highlight-color !important;
      background-color: $highlight-bg-color !important;
    }

    &.ant-menu-item-disabled {
      color: $sidebar-color-disabled !important;
      background-color: transparent !important;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title,
    .ant-menu-submenu-title > .anticon {
      font-size: 18px;
      font-weight: bold;
    }
    .ant-menu-submenu-title:hover {
      color: $sidebar-color !important;
    }
    .ant-menu-submenu-title:active {
      background-color: transparent !important;
    }
  }

  .ant-menu.ant-menu-sub {
    background: $submenu-bg-color !important;

    .ant-menu-item {
      padding-left: 25px !important;
      font-size: 14px;
      font-weight: normal;

      &.ant-menu-item-selected {
        background-color: $highlight-color !important;
        color: $sidebar-color !important;
      }
    }
  }
}

.control-center-menu-skeleton {
  height: 20px !important;
  background: rgba(255, 255, 255, 0.1) !important;

  &::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 25%, rgba(255, 255, 255, 0.15) 37%, rgba(255, 255, 255, 0.06) 63%) !important;
  }
}

@media screen and (max-width: $breakpoint-md) {
  body,
  .ant-layout {
    overflow-x: hidden;
  }

  main.ant-layout-content {
    min-width: 400px;
  }
}
