.root {
  position: relative !important;

  .loadingContainer {
    width: 100%;
    height: 100%;
  }
}

.container {
    width: 100%;
    height: calc(100vh - 167px);

    iframe {
      width: 100%;
      height: 100%;
      border-radius:10px
    }
}
