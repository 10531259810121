@import '../../../../variables';

.cardWrapper {
  margin-right: 10px;
  margin-top: 10px;
  min-width: 240px;
  cursor: pointer;
}

.cardTitleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: auto;
}

.cardTitle {
  text-transform: capitalize;
  margin-bottom: 0 !important;
  font-size: 1.1em;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardTitleIcon {
  transform: rotate(90deg);
  cursor: pointer;
}

.cardImage {
  border-radius: 0 !important;
  max-width: 200px !important;
  min-width: 100%;
  height: 135px;
  object-fit: cover;
  object-position: top;
}

.cardTag {
  margin-bottom: 15px;
}

.cardDescription {
  max-height: 45px;
  display: -webkit-box;
  display: -moz-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.button {
  border: none;
  background-color: transparent;
}

.buttonDelete {
  @extend .button;
  color: $magenta-900;

  &:hover {
    color: white;
  }
}

.buttonEdit {
  @extend .button;
  color: $txt-link-color;
}
