.inputWrapper {
  flex-flow: row;
}

.inputFull {
  width: 100%;
}

.inputHalf {
  width: 50%;
}

.marginRightSmall {
  margin-right: 5px;
}

.marginLeftSmall {
  margin-left: 5px;
}
