@import './base';
@import './variables';

#root {
  height: 100vh;
}

main.ant-layout-content {
  padding-bottom: 20px;
}

// === Ant.D Icon ===
.anticon {
  vertical-align: 1px;
}

// === Ant.D Search ===
.antSearchBar {
  &.ant-input-search {
    max-width: 400px;
  }

  .ant-input-affix-wrapper,
  .ant-input-group-addon > .ant-input-search-button {
    height: $heightButtonPrimary;
    box-shadow: $shadow-4;
    font-size: 18px;
  }

  .ant-input-prefix {
    color: $txt-placeholder-color;
    margin-right: 10px;
    margin-left: 5px;
  }

  .ant-input-affix-wrapper {
    background-color: $white-1;
  }

  .ant-input-group-addon > .ant-input-search-button {
    background-color: $bg-secondary-color;
  }
}

.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: auto;
  margin-top: 5px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: 0;
}

.ant-steps-item-description {
  max-width: calc(100% - 30px) !important;
}

.ant-image-preview-operations-operation-rotateLeft,
.ant-image-preview-operations-operation-rotateRight {
  display: none;
}

.ant-form-item-has-error .quill {
  border: 1px solid red !important;
}

// AntD Collapse Full 
.control-center-collapse-full.ant-collapse {
  .ant-collapse-header {
    padding-left: 0px;
    padding-right: 0px;

    .ant-collapse-expand-icon > .anticon {
      font-size: 18px;
    }
  }
}

#zohogc-helper-main {
  position: fixed !important;
}

#chatbase-bubble-button {
  display: none !important;
}

#chatbase-bubble-window {
  pointer-events: all;
}
