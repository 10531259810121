.modalContentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  min-height: 400px;

  p {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.cardItemShield {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
}
