@import "../../variables";
@import '../../base';
@import '../../Common.module.scss';

$bg-btn-upgrade: $bg-secondary-color;
$txt-title-color: $bg-octonary-color;
$txt-link-color-effect: $bg-secondary-color;
$txt-btn-color: $bg-primary-color;
$bg-card-container: $txt-primary-color;
$txt-color-banner: $bg-primary-color;
$txt-color-subscription: $bg-octonary-color;


.addTicket {
  border-radius: 20px;
  width: fit-content;
  margin: auto;
  min-width: 305px;
  font-size: 18px !important;
  font-weight: 600 !important;
  height: 45px;
  box-shadow: rgba(62, 167, 253, 0.2) 0px 2px 8px 4px;
  margin-top: 20px;
}

.wrapper {
  text-align: left;
}

.textInfoModal {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 40px;
  width: fit-content;
  margin: auto;
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  word-spacing: 3px;
}

.textInfo {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 40px;
  width: fit-content;
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  word-spacing: 5px;

  ul {
    list-style: none;
    li {
      margin-bottom: 10px;
    }
  }

  ul li::before {
    content: "\2022";
    color: $bg-nonary-color;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.titleCard {
  text-align: left;
  color: $bg-octonary-color;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.helpCard {
  background-color: #ebf8fd;
  margin: 20px 0;
  padding: 20px;
}

.txtLink {
  color: $txt-link-color;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    border-bottom: 1px solid $txt-link-color-effect;
    color: $txt-link-color;
  }
}

.zohoIcon {
  padding: 0px;
  margin: 0px;
}

.container {
  display: flex;
  width: 90%;
  padding: 0 2%;
  margin: auto;
  align-items: center;
}

.zohoTicketLink {
  padding: 20px;
  margin: 20px;
}

.ticketContainer {
  text-align: center;
  margin-left: 50px;
  box-sizing: border-box;
}

.btnBox {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.btnDocs {
    border-radius: 20px;
    height: 45px;
    background-color: $bg-btn-upgrade;
    color: $bg-btn-upgrade;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 310px;
    margin-bottom: min(30px, 3%);
    box-shadow: rgba(62, 167, 253, 0.2) 0px 2px 8px 4px;
    a {
      color: $txt-btn-color;
      text-decoration: none;
      letter-spacing: 1px;
      font-family: "Poppins";
      font-size: 18px;
    }
}

.btnUpgrade {
  border-radius: 20px;
  height: 45px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 350px;
  background-color: $bg-octonary-color;
  box-shadow: rgba(255, 60, 164, 0.281) 0px 2px 8px 4px;
  a {
    color: #fff;
    text-decoration: none;
    letter-spacing: 1px;
    font-family: "Poppins";
    font-size: 18px;
  }
}

.txtSubscription {
  color: $txt-color-subscription;
}

.linkBtn {
  @extend %btn-as-link;
}

.agentContainer {
  padding: 0 .5rem;

  p {
    margin-bottom: 0;
  }
}

.tableWrapper {
  padding: 0 1rem 1rem 1rem;
  text-align: right;
}
