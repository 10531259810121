.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #EBEBEF;
    box-shadow: 3px 4px 12px rgba(5, 9, 59, 0.13);
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    max-width: 800px;
    min-width: 800px;
    min-height: 87px;
    max-height: 87px;
    overflow: hidden;
    cursor: pointer;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-size: 16px;
        margin: 0;
        text-align: start;
    }

    p {
       color: #666666;
       font-size: 13px;
    }

    .title,
    .message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .title {
        font-size: 20px;
        width: 750px;
    }

    .message {
        width: 700px;
    }

    .detailsLink {
        color: #74c3ff;
    }

    .check {
        color: #00CE3A;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.notificationMessageWrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    b {
        margin: 0
    }

    h1 { font-size: 25px;}
    h2 { font-size: 23px;}
    h3 { font-size: 21px;}
    h4 { font-size: 19px;}
    h5 { font-size: 17px;}
    h6 { font-size: 15px;}
    p { font-size: 13px;}

    img {
        max-width: 100%;
    }
}

.buttonsActionsWrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.button {
    border: none;
    padding: 5px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    width: 130px;
    height: 45px;
    cursor: pointer;
}

.buttonPrimary {
    @extend .button;
    color: #f9f9f9;
    background: #1f9fff;
}
