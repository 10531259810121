@import '../../breakpoints';
@import "../../Common.module.scss";

.container {
    width: 100%;
    background-color: $txt-primary-color;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;

    img {
        min-height: 100% !important;
        min-width: 420px !important;
    }

    .txtContainer {
        width: 370px;
        align-self: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    
    .txtPrimary {
        color: $txt-banner-primary;
        font-size: $fs-subtitle-primary;
        
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .txtSecondary {
        color: white;
        font-size: $fs-title-primary;
        width: 370px;
        font-weight: bold;
        
    }
}

@media (max-width: $breakpoint-lg) {
    .container {
      flex-direction: column-reverse;
    }
    .txtContainer {
        max-width: 100%;
        padding: 20px;
    }
}

@media (max-width: $breakpoint-sm) {
    .container {
        img {
          min-width: 100% !important;
        }
      }
}
