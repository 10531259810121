// Account Colors
$bg-primary-color: #f9f9f9 !default;
$bg-secondary-color: #1f9fff !default;
$bg-tertiary-color: #0ab870 !default;
$bg-quaternary-color: #ffc743 !default;
$bg-quinary-color: #edeef2 !default;
$bg-senary-color: #f02770 !default;
$bg-septenary-color: #d4d6dd !default;
$bg-octonary-color: #E63273 !default;
$bg-nonary-color: #7F1BC5 !default;
$txt-primary-color: #05093b !default;
$txt-secondary-color: #5a5c64 !default;
$txt-quaternary-color: #1f9fff !default;
$txt-placeholder-color: #cccccc !default;
$border-primary-color: #d3cece !default;
$border-secondary-color: #cbccd2 !default;
$border-tertiary-color: #a7a6a6 !default;
$txt-link-color: #0092ff !default;
$txt-banner-primary: #FCC921 !default;

// Account Fonts
$fs-title-primary: 24px !default;
$fs-title-secondary: 20px !default;
$fs-subtitle-primary: 18px !default;
$fs-subtitle-secondary: 16px !default;

// Manage Colors
$manage-primary-color: #38aae1 !default;
$manage-txt-color: #37474f !default;
$manage-txt-error-color: #d8000c !default;
$manage-bg-error-color: #ffd2d2 !default;
$manage-txt-success-color: #4f8a10 !default;
$manage-bg-success-color: #dff2bf !default;

//Manage Values
$manage-border-radius: 4px !default;

// Memo colors
$background-backoffice: #d4e7f7 !default;
$border-backoffice: #4682e9 !default;
$background-support: #f5e8f6 !default;
$border-support: #d9acdd !default;
$background-billing: #d7f2e1 !default;
$border-billing: #3f9262 !default;
$background-sales: #f6efb4 !default;
$border-sales: #f8db99 !default;
$background-task: #e1caee !default;
$border-task: #9455dc !default;
$background-upgrade: #f9e0de !default;
$border-upgrade: #e8aea9 !default;
$background-downgrade: #caf8f7 !default;
$border-downgrade: #2ac4be !default;
$background-debt_collection: #f2a749 !default;
$border-debt_collection: #e0a458 !default;
$background-qa: #aadfff !default;
$border-qa: #81adc8 !default;
$background-quota: #aadfff !default;
$border-quota: #81adc8 !default;
$background-customerSuccess: #f1f8d1 !default;
$border-customerSuccess: #cfdb99 !default;
$background-loyalty: #f3d9dc !default;
$border-loyalty: #c78283 !default;
$background-referral: #fddeff !default;
$border-referral: #8e4c90 !default;
$background-other: #dcdcdc !default;
$border-other: #8c8d91 !default;

// Buttons & links
$padding-link-vertical: 10px;
$padding-link-horizontal: 30px;
$margin-link-vertical: 5px;
$margin-link-horizontal: 10px;
$border-raius: 30px;

$font-weight: 600;
$font-weight-1: 400;

// Header
$headerImageWitdhSize : 160px;

// Especial
$borderRadiusPrimary: 8px;
$heightButtonPrimary: 41px;

// My Site
$border-radius-1: 8px;

$primary-1: #0b0b0b;

$neutral-1: #BBC4D0;
$neutral-2: #7b7676;
$neutral-3: #dedede;
$neutral-4: #334D6E;
$neutral-5: #E5E8EB;

$blue-1: #1a78fb;
$blue-2: #232b6e;
$blue-3: #111874;
$blue-4: #09138E;
$blue-5: #031063;
$blue-6: #250a4d;
$blue-7: #05093b;
$blue-8: #030c37;
$blue-9: rgb(3,9,119);
$blue-10:rgba(12,32,146,1);
$blue-11: rgba(76,55,151,1);
$blue-12: rgba(46,8,74,1);

$green-50: #F2FDF8;
$green-100: #E5FBF2;
$green-200: #CCF6E5;
$green-300: #B3F2D8;
$green-400: #99EECB;
$green-500: #80E9BE;
$green-600: #66E5B1;
$green-700: #4DE1A4;
$green-800: #33DD97;
$green-900: #00D47D;

$magenta-50: #FEF4F8;
$magenta-100: #FDE9F1;
$magenta-200: #FCD4E2;
$magenta-300: #FBBED4;
$magenta-400: #F9A9C6;
$magenta-500: #F793B7;
$magenta-600: #F67DA9;
$magenta-700: #F5689B;
$magenta-800: #F3528D;
$magenta-900: #F02770;

$violet-900: #960AFF;

$primary-highlight-50: #F2FAFF;
$primary-highlight-100: #E5F4FF;
$primary-highlight-200: #CCE9FF;
$primary-highlight-300: #B3DEFF;
$primary-highlight-400: #99D3FF;
$primary-highlight-500: #80C8FF;
$primary-highlight-600: #66BDFF;
$primary-highlight-700: #4DB3FF;
$primary-highlight-800: #33A8FF;
$primary-highlight-900: #0092FF;

$text-menu-600: #8594AB;
$text-menu-800: #5C7188;

$orange-1: #fde3cf;
$orange-7: #f56a00;

$white-1: #f9f9f9;
$white-2: #fdfdfd;

$shadow-color-1: rgba(5, 9, 59, 0.06);
$shadow-color-2: rgba(87, 87, 133, 0.16);
$shadow-color-3: rgba(4, 84, 145, 0.14);
$shadow-color-4: rgba(11, 97, 225, 0.12);
$shadow-color-5: rgba(5, 9, 59, 0.13);

// Shadows
$shadow-1: 2px 4px 9px $shadow-color-1;
$shadow-2: 3px 4px 12px $shadow-color-2;
$shadow-3: -3px 4px 12px $shadow-color-2;
$shadow-4: 1px 2px 7px $shadow-color-3;
$shadow-5: 6px 6px 15px 0px $shadow-color-2;

$shadow-button-1: 2px 2px 10px $shadow-color-1;
$shadow-button-2: 0px 6px 20px $shadow-color-4;
$shadow-button-3: 0px 10px 28px $shadow-color-4;

// Sidebar
$sidebar-color-default: #E6E6EB;
$sidebar-color-box-hover: #2F335C;
$sidebar-color-text-hover: #FFFFFF;
$sidebar-color-disabled: #505376;
$sidebar-color-selected: $primary-highlight-900;

// AntD
$error-input-color: #ff4d4f;
