@import '../../../../variables';
$background-container-color: $bg-quinary-color;

.containerSp {
  margin-top: 15px;
  border-radius: 8px;
  padding: 0 1.5em;
}

.title {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 1.8rem
}

.aidanContainer {
  margin-bottom: 20px;
  border-radius: $borderRadiusPrimary;
  background-position: center;
  background-size: cover;
  background-image: url('../../../../assets/aidan-background.png');

  .aidanContainerTitle {
    padding-top: 40px;
    text-align: center;
    font-weight: bold;
  }
  .aidanContainerSubtitle {
    text-align: center;
    color: white;
  }

  .aidanContainerIframe {
    padding: 20px 40px;
  }
}
