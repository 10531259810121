@import "../../breakpoints";
@import "../../variables";

$txt-mainElement-color: $txt-primary-color;
$fs-mainElement: $fs-title-primary;
$background-card-color: $bg-primary-color;

.container {
  background-color: $background-card-color;
  display: flex;
  width: 100% !important;
  border-radius: 8px;
  justify-content: space-between;
  padding: 20px 0;
  box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.2);

  &:last-child {
    border-bottom: none;
  }

  img {
    width: 100%;
    max-width: 150px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .mainElement {
    text-align: center;
    width: 250px;
    color: $txt-mainElement-color;
    font-size: 22px;
    font-weight: 600;
    margin: 10px;

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: left;
      margin-left: 15%;
    }
  }

  .element {
    max-width: 100%;
    padding: 10px;
    flex: 1;
  }

  .rows {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: $breakpoint-lg) {
  .mainElement {
    min-width: 100%;
  }
  .container,
  .row {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
}
