@import "../../../Common.module.scss";
@import "../../../variables";
@import "../../../breakpoints";

.subscriptionDetail {
    position: sticky;
    top: 25px;
    border-radius: $border-radius-1;
    border: 1px solid $blue-1;
    min-width: 350px;
    box-shadow: 0 0 8px 0 $blue-1;

    .body {
        padding: 4px 15px;

        .content {
            .contentElement {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 5px 0px;
            }

            .logo {
                width: 80px;
        
                img {
                    width: 100%;
                }
            }
        
            .title {
                font-size: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 200px;
                overflow: hidden;
                text-decoration: none;
            }
        
            .titleLinkless {
                font-size: 18px;
                text-decoration: underline;
                font-weight: bold;
            }

            .titleLinklessSecondary {
                font-size: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 200px;
                overflow: hidden;
                text-decoration: none;
                color: $neutral-2;
            }

            .subtitle {
                color: $neutral-2;
            }

            .subtitleSecondary {
                font-size: 12px;
                color: $neutral-2;
            }
        }

        .footer {
            padding: 30px 0px;
            text-align: center;

            a {
                text-decoration: none;
                display: inline-block;
            }
        }
    }
}

.closeButtonContent {
    display: flex;
    justify-content: flex-end;
    padding: 4px 10px;
    background-color: $white-1;
    border-top-left-radius: $border-radius-1;
    border-top-right-radius: $border-radius-1;

    .closeButton {
        border: none;
        background-color: transparent;
    }
}
