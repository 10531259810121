@import '../../../variables';
@import '../../../Common.module.scss';

.main {
  display: flex;
  align-items: center;
  padding: 5px;
  width: min-content;

    .logicOperator,
    .filterSelector,
    .fieldLookupsOptions {
        margin-right: 5px;
    }

    .logicOperator {
        width: 80px;
        max-width: 80px;
        text-align: center;
        font-weight: bold;
    }

    .filterSelector {
        margin-right: calc(34px + 5px);
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            color: inherit;
        }
    }

    .filterSelector,
    .fieldLookupsOptions,
    .defaultInputValue {
        width: 150px;
    }
    .rangeDateValue {
        width: 250px;
    }
}
