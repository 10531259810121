@import '../../variables';
@import 'react-tabs/style/react-tabs.css';

$bg-tab-color: $bg-tertiary-color;
$txt-tab-color: $bg-primary-color;
$bg-tab-selected-color: $primary-highlight-800;
$bg-tab-panel-color: $bg-primary-color;
$background-container-color: $bg-quinary-color;

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
  
    &__tab {
      display: inline-block;
      border: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 15px 20px;
      background: $bg-tab-color;
      color: $txt-tab-color;
      cursor: pointer;
      border-radius: 8px 8px 0px 0px;
      margin-right: 4px;
      min-width: 94px;
  
      &--selected {
        background: $bg-tab-selected-color;
        border-color: $bg-tab-selected-color;
        border: 1px solid $bg-tab-selected-color;
      }

      &--selected:before {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid $bg-tab-selected-color;
        bottom: -12px;
        content: "";
        height: 0;
        left: 50%;
        margin-left: -12px;
        position: absolute;
        width: 0;
    }
    
      &--disabled {
        cursor: default;
      }
  
      &:focus {
        box-shadow: 0 0 5px hsl(0, 0%, 0%);
        border-color: hsl(0, 0%, 0%);
        outline: none;
  
        &:after {
          content: "";
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
        }
      }
    }
  
    &__tab-panel {
      display: none;
  
      &--selected {
        display: block;
        border-radius:8px;
      }
    }
}
