@import "../../variables";
$bg-loading-color: #f9f9f9;
$txt-loading-color: $txt-primary-color;

.container {
    background: $bg-loading-color;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $txt-loading-color;
}

.icon {
    cursor: pointer;
}