@import "../../Common.module.scss";
@import "../../variables";
@import "../../breakpoints";

.main {
    display: flex;
    justify-content: center;
    width: 100%;

    .content {
        width: 100%;
    }

    .detail {
        margin-top: 25px;
        margin-left: 25px;
        max-width: 350px;
    }

    .resultMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 100px 0px;

        .resultMessageIcon {
            font-size: 64px;
            padding-bottom: 10px;
        }
    }

    .paginate {
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }
}
