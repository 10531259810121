@import '../../../../variables';
@import '../../../../breakpoints';

.listTitle,
.listWrapper,
.listDatesWrapper,
.listTitleWrapper,
.listContent {
  display: flex;
}

.listTitle,
.listDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listWrapper {
  position: relative;
  z-index: 50;
  align-items: center;
  margin-bottom: 20px;
  border-radius: $border-radius-1;
  background-color: #fff;
  min-height: 110px;
  max-height: 110px;
  overflow: hidden;
  cursor: pointer;

  p {
    margin: 0;
  }
}

.listImage {
  min-height: 150px;
  max-height: 250px;
  min-width: 130px;
  object-fit: contain;
  max-width: 200px !important;
  width: 200%;
}

.listTitleWrapper {
  justify-content: space-between;
}

.listTitle {
  align-items: center;
  color: $blue-7;
  margin-bottom: 13px;
  max-width: 400px;

  h4 {
    margin-bottom: 0;
  }

  span {
    margin-left: 20px;
  }
}

.listContent {
  width: 100%;
  flex-direction: column;
  margin-left: 15px;
  padding: 0 15px;
}

.listDatesWrapper {
  p:nth-child(2) {
    margin-left: 40px;
    margin-bottom: 10px;
  }
}

.listDescription {
  color: $txt-secondary-color;
  max-width: 400px;
}

.listButtons {
  height: fit-content;

  button {
    border: none;
    background-color: transparent;
  }

  button:nth-child(1) {
    color: $magenta-900;
  }

  button:nth-child(2) {
    color: $txt-link-color;
  }

  .listDivider {
    color: $neutral-3;
    margin-left: 8px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .listWrapper {
    flex-direction: column;
  }
}
