@import '../../../variables';
@import '../../../breakpoints';

.dropDownWrapper,
.wrapperDropDownLinks,
.dropDownUserDetails {
  display: flex;
}

.dropDownWrapper {
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  cursor: default;
}

.dropDownUserDetails {
  align-items: center;
  min-width: 120px;
  width: 100%;
}

.avatar {
  background-color: $blue-7;
  color: #ffffff;
  min-width: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.avatarDropDown {
  @extend .avatar;
  margin-left: 0;
  cursor: default;
}

.userDetailsWrapper {
  margin-left: 10px;
}

.userName,
.userRole,
.inputName {
  margin-bottom: 0;
}

.userName {
  text-transform: capitalize;
  color: $text-menu-800;
}

.userRole {
  line-height: 10px;
  font-size: 12px;
  color: $text-menu-600;
}

.drawerTagRole {
  margin-top: 10px;
}

.buttonOpenDrawer {
  font-size: 10px;
  padding: 4px 10px;
  width: 100%;
  margin-top: 20px;
}

.link {
  text-decoration: none;
}

.logOutIcon {
  margin-right: 10px;
}

.wrapperDropDownLinks {
  justify-content: space-evenly;
  font-size: 10px;

  a {
    display: block;
    @extend .link;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .avatar {
    margin-left: 10px;
  }
}
