@import '../../../variables';
@import '../../../base';
@import '../../../breakpoints';

.attributeItemsWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.attributeItemsCard {
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: hidden;
}

.noItemsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.attributeItemsCardLoading {
  height: 320px;
  width: 240px;
  box-shadow: $shadow-5;
  background: linear-gradient(90deg, $bg-septenary-color 8%, $bg-primary-color 18%, $bg-septenary-color 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: skeleton 1.6s linear infinite;
  margin-right: 10px;
  margin-top: 10px;
}


@media screen and (max-width: $breakpoint-lg) {
  .attributeItemsCard {
    justify-content: center;
  }
}
