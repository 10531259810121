@import "../../variables";

$title-color: $txt-quaternary-color;
$content-color: $txt-primary-color;
$content-color-empty: $txt-primary-color;
$fs-title-content: $fs-subtitle-secondary;
$txt-edit-color: $bg-tertiary-color;
$txt-error-color: $bg-senary-color;
$txt-link-color-effect: $bg-senary-color;

.wrapper {
  position: relative;
  text-align: justify;
  .title,
  .content {
    font-size: $fs-title-content;
  }

  .title {
    color: $title-color;
    text-transform: capitalize;
    font-weight: bold;
  }

  .content,
  .empty {
    cursor: pointer;
    color: $content-color;
  }

  .empty {
    font-weight: 400;
    font-size: 16px;
    font-style: italic;
    color: $content-color-empty;
    visibility: visible;
  }

  &:hover {
    .pointer {
      display: inline;
    }
  }

  .pointer {
    position: absolute;
    cursor: pointer;
    margin-left: 10px;
    display: none;
    font-size: $fs-title-content;
    color: $content-color;
  }

  textarea {
    max-width: 17vw;
    white-space: pre-wrap;
  }

  .input {
    font-size: $fs-title-content;
    min-width: 100px;
    max-width: 100%;
    outline: none !important;
    border: 1px solid $content-color-empty;
    box-shadow: 0 0 10px #719ece;
    border-radius: 5px;
    padding: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    background-color: #ffff;

    &[type="checkbox"] {
      min-width: auto;
      max-width: none;
    }

    &:disabled {
      background-color: #999;
      cursor: wait;
    }
  }

  .block {
    cursor: auto;
  }

  .content_textarea {
    white-space: pre-wrap;
  }
}
.message {
  padding-top: 5px;
  font-size: 13px;
  font-style: italic;

  .edit {
    color: $txt-edit-color;
  }

  .error {
    color: $txt-error-color;
  }
}
.container {
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  box-sizing: border-box;
}

.url:link {
  color: $txt-link-color-effect;
  text-decoration: none;
}

.url:hover {
  opacity: 0.8;
  border-bottom: 1px solid $txt-link-color-effect;
}
