@import '../../../variables';
@import '../../../Common.module.scss';

.main {
    padding: 25px;
    padding-bottom: 0px;

    .container {
        border-radius: $borderRadiusPrimary;
        box-shadow: $shadow-4;
        padding: 10px 20px;
        width: 100%;
    
        .logicOperator {
            color: $bg-secondary-color;
        }
    
        .tag {
            background-color: $bg-primary-color;
            color: $txt-secondary-color;
            border-radius: $borderRadiusPrimary;
            padding: 10px;
            margin: 5px;
            max-height: max-content;
            max-width: max-content;
            min-width: min-content;
            height: max-content;
            text-transform: capitalize;
    
            .tagLookup {
                color: $bg-secondary-color;
            }
    
            .tagValue {
                color: black;
            }
        }
    }
    
    .results {
        color: $neutral-2;
        margin-top: 10px;
        margin-bottom: 0px;
    }
}
