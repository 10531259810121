@import '../../variables';

$bg-mainContent-color: $bg-primary-color;

.main {
  padding: 7%;
  margin: 0px;
  height: 100vh;
  background-color: $bg-mainContent-color;
}
