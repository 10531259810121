@import './variables';

// === Links ===

.link,
.linkGoToMDE,
.linkNormalButton,
.linkCancelButton,
.linkUpgradeSubscription {
  max-height: 44px;
  text-decoration: none;
}

.link,
.button {
  border: none;
  background-color: transparent;
  color: $bg-secondary-color;
  white-space: nowrap;
  width: fit-content;
}

.link[disabled],
.button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.link:hover {
  color: #74c3ff;
  transition: color 0.5s;
}

.linkGoToMDE,
.linkNormalButton,
.linkCancelButton,
.linkUpgradeSubscription {
  display: block;
  padding: $padding-link-vertical $padding-link-horizontal;
  color: white;
  font-weight: $font-weight;
  border-radius: $border-raius;
  width: max-content;
  margin: $margin-link-vertical $margin-link-horizontal;
}

.linkNormalButton {
  color: white;
  background-color: $bg-secondary-color;
}

.linkCancelButton {
  background-color: $manage-txt-error-color;
}

.linkUpgradeSubscription {
  background-color: $bg-secondary-color;
}

.linkGoToMDE:hover,
.linkUpgradeSubscription:hover,
.linkCancelButton:hover,
.linkNormalButton:hover {
  color: white;
}

.linkGoToMDE {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 15px;
  border-radius: 6px;
  font-weight: 400;
  background-color: $bg-senary-color;
  height: 32px;
  margin: 0;
}

// === Buttons ===

.button {
  border: none;
  padding: 1em 2em;
  font-weight: bold;
  border-radius: 8px;
  background-color: transparent;
  width: max-content;
}

.spanText {
  margin: 0 10px;
}

.buttonGreen {
  @extend .button;
  color: $white-1;
  background-color: $bg-tertiary-color;
  text-transform: uppercase;
  font-size: 12px;
}

.buttonForm {
  @extend .button;
  color: white;
  background-color: $bg-secondary-color;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
}

.buttonWelcomeModal {
  @extend .button;
  display: block;
  color: white;
  background-color: $violet-900;
  text-transform: capitalize;
  text-decoration: none;
  font-size: 16px;
  border-radius: 50px;
  height: 50px;
  transition: transform .5s;
}

.buttonWelcomeModal:hover {
  color: white;
  transform: scale(1.1);
  transition: transform .5s;
}

.buttonWhite {
  @extend .button;
  line-height: 0px;
  padding: 10px 9px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: $fs-subtitle-primary;
  background-color: $white-1;
  border-radius: 8px;
  color: $txt-secondary-color;
  height: $heightButtonPrimary;
  box-shadow: $shadow-4;
  margin: 0px;
}

.buttonWhiteDelete {
  @extend .buttonWhite;

  &:hover {
    background-color: $manage-txt-error-color;
    color: $white-1;
    transition: all .5s;
  }
}

.buttonGreen:hover {
  background-color: lighten($color: $bg-tertiary-color, $amount: 3);
  transition: background-color 0.5s;
}

.buttonDeleteTableItem {
  @extend .button;
  margin: 0 10px;
  padding: 0;
  color: $bg-senary-color;
}

.buttonEditTableItem {
  @extend .button;
  padding: 0;
  color: $bg-quaternary-color;
}

.buttonAddItem,
.buttonDeleteItem,
.buttonUpgrade,
.buttonPrimary,
.buttonOutLine,
.buttonCancelAction {
  @extend .button;
  display: flex;
  align-items: center;
  color: white;
  padding: 5px 10px;
  margin: 10px;
  font-weight: normal;
}

.buttonAddItem {
  background-color: $bg-tertiary-color;
  padding: $padding-link-vertical $padding-link-horizontal;
  font-weight: $font-weight;
  border-radius: $border-raius;
}

.buttonDeleteItem {
  background-color: #ff4444;
}

.buttonUpgrade {
  background-color: $bg-octonary-color;
}

.buttonPrimary {
  background-color: $bg-secondary-color;
  padding: $padding-link-vertical $padding-link-horizontal;
  font-weight: $font-weight;
  border-radius: $border-raius;
}

.buttonOutLine {
  @extend .buttonPrimary;
  background-color: transparent;
  border: 2px solid $bg-secondary-color;
  text-decoration: underline;
  color: $bg-secondary-color;
}

.buttonCancelAction {
  background-color: $manage-txt-error-color;
  border-radius: 30px;
  font-weight: $font-weight;
  padding: $padding-link-vertical $padding-link-horizontal;
}

.normalButtonAccordion {
  @extend .buttonPrimary;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 200px;
  min-height: 44px;

  .spanText {
    transition: transform 1s;
    transform: scale(0, 0);
  }

  & svg {
    font-size: 20px;
    transition: transform 1s;
    transform: translateX(-180%);
  }

  &:hover {
    .spanText {
      transform: scale(1, 1);
    }

    & svg {
      transform: translateX(0);
    }
  }
}

.buttonWithoutBoder {
  border: none;
  background: transparent;
}

.buttonBlock {
  width: 100%;
  height: 80px;
  border: none;
  font-size: 20px;
  font-weight: bold;
}

.buttonBlockBottomNextStep {
  @extend .buttonBlock;
  border-radius: 0px 0px 8px 8px;
  color: white;
  background-color: $bg-secondary-color;
}

.buttonBlockBottomPreviusStep {
  @extend .buttonBlock;
  border-radius: 0px 0px 8px 8px;
  color: $bg-secondary-color;
  background-color: white;
}

// === TOAST ===

.toast {
  color: #f9f9f9;
  background-color: #05093b;
  background-repeat: no-repeat;
  background-image: url('./assets/menuBackgroundShort.jpg');
}

.toastHead {
  background-color: transparent;
}

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  width: 100%;
}

// Accordeon

.infoAccordeon {
  font-size: 25px;
  position: relative;
  color: #05093b;
  margin-right: 10px;
}

.accordeonHeader {
  color: #05093b;
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  color: #05093b !important;
  background-color: transparent !important;
}

// Onboarding

.onboardingTitleBlue {
  color: $txt-quaternary-color;
  margin: 0 5px;
}

// Banner Upgrade
.bannerUpgradeTxtPrimary {
  font-size: $fs-subtitle-primary;
  color: $txt-banner-primary;
  margin-bottom: 0px;
}

.bannerUpgradeTxtSecondary {
  font-size: $fs-title-primary;
  color: white;
  font-weight: bold;
}

.bannerRedirectTxtPrimary {
  font-size: $fs-title-primary;
  color: white;
}

.bannerRedirectBoldColor {
  color: $bg-tertiary-color;
}

// ==== Dinamic content
.bannerContactSupport {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  background-color: $manage-bg-error-color;
  color: $manage-txt-error-color;
  border-radius: $border-radius-1;

  p {
    margin-bottom: 0;
  }
}

.boldText {
  margin: 0 3px;
}

.antTabsContentHolder {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.titleColorPrimary {
  color: $blue-1;
}

// input label
.blocked {
  display: inline;
  color: $neutral-1;
}

.tooltipInformation {
  color: $neutral-1;
  font-size: 12px;
  line-height: 12px;
  margin: 0px 10px;
}

.popoverContent {
  max-width: 300px;
}

// New buttons and links
.buttonSave,
.buttonSave:hover,
.buttonSave:focus,
.buttonSave:active {
  background-color: $green-900 !important;
}

.linkCancel,
.linkCancel:hover,
.linkCancel:focus,
.linkCancel:active {
  color: $magenta-900 !important;
}
