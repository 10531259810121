@import "../../variables";
@import "../../breakpoints";

$bg-tr-odd-color: $bg-primary-color;
$bg-th-color: $bg-septenary-color;
$txt-th-color: $txt-secondary-color;
$txt-td-color: $txt-primary-color;
$pagination-text-color: $txt-primary-color;
$border-td-color: $border-secondary-color;

.tableLoader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #1f9fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: tableLoader1 0.6s infinite;
  }

  div:nth-child(2) {
    left: 8px;
    animation: tableLoader2 0.6s infinite;
  }

  div:nth-child(3) {
    left: 32px;
    animation: tableLoader2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 56px;
    animation: tableLoader3 0.6s infinite;
  }
}

@keyframes tableLoader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes tableLoader3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes tableLoader2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.wrap {
  display: block;
  max-width: 100%;
  padding: 0 1rem 1rem 1rem;
  box-sizing: border-box;
}

.tableWrapper {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;

  th {
    font-weight: 600;
  }

  td {
    color: $txt-primary-color;
    word-break: break-all;
  }

  .tableAccounts {
    width: 100%;
    border-spacing: 0px;
    table-layout: auto;
    border: 1px solid $border-td-color;

    tr:hover {
      background-color: rgba(0, 0, 0, 0.055);
    }

    tr:nth-child(even) .tdAccounts {
      background-color: rgba(0, 0, 0, 0.055);
    }
    tr:nth-child(odd) .tdAccounts {
      background: rgba(0, 0, 0, 0.013);
    }

    .thAccounts {
      background: $bg-th-color;
      color:$txt-th-color;
      font-size: $fs-subtitle-secondary;
      height: 30px;
      line-height: 1.3;
    }

    .thAccounts,
    .tdAccounts {
      margin: 0;
      padding: 0.5rem;
      text-align: center;
      border-bottom: 1px solid $border-secondary-color;
      border-right: 1.5px solid $border-secondary-color;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      
      :last-child {
        border-right: 0;
      }
    }
  }

  .arrowIcon {
    font-size: $fs-title-secondary;
    margin-left: 10px;
  }
}

.tdTextSpace {
  white-space: nowrap;
}

.tdTextRight,
.tdTextLeft {
  display: inline-block;
  width: 100%;
}

.tdTextRight {
  text-align: right;
}

.tdTextLeft {
  text-align: left;
}

.tdMaxWithText {
  max-width: 280px;
}

.pageIndex {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
  color:$pagination-text-color;
}

.pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 5px;
  width: fit-content;
}

.previous,
.next {
  display: flex;
}

.previousBtn,
.nextBtn,
.gotoPageBtn {
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  height: 100%;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
  width: 80px;
  border: 1px solid $border-secondary-color;
}

.previousBtn[disabled],
.gotoPageBtn[disabled],
.nextBtn[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

.pageInfo {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.pageSizeOptions {
  margin: 3px 10px;
  border: 1px solid $border-secondary-color;
  background: $bg-tr-odd-color;
  padding: 7px 10px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  color: $pagination-text-color;
  cursor: pointer;
}

.quantity {
  color: $txt-quaternary-color;
}

.angleIcon {
  font-size: $fs-title-secondary;
}

@media only screen and (max-width: $breakpoint-xs) {
  .previousBtn,
  .nextBtn,
  .gotoPageBtn {
    width: 40px;
  }
  .pageSizeOptions,
  .quantity {
    display: none;
  }
}

@media only screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-xs) {
  .previousBtn,
  .nextBtn,
  .gotoPageBtn {
    width: 40px;
  }
}
