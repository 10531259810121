@import './variables';

$txt-color-btn: $bg-primary-color;
$btn-add-color: $bg-senary-color; 
$txt-color-btn: $bg-primary-color;
$bg-btn-save: $bg-secondary-color;
$txt-active: $bg-tertiary-color;
$txt-inactive: $bg-senary-color;

%btn-as-link {
    background: none;
    border: none;
    padding: 0;
    color: $txt-quaternary-color;
    text-decoration: underline;
    cursor: pointer;
}

%btn-add {
    color: $btn-add-color;
    border: 3px solid $btn-add-color;
    height: 45px;
    border-radius: 24px;
    padding: 5px;
    font-weight: 600;
    opacity: 0.8;
    width: 170px;
    margin: 20px 10px;
    font-size: 16px;
    cursor: pointer;
}

%btn-save {
    height: 45px;
    background: $bg-btn-save;
    color: $txt-color-btn;
    border: none;
    border-radius: 12px;
    padding: 5px;
    font-weight: 600;
    width: 130px;
    margin: 20px 10px;
    font-size: 16px;
    cursor: pointer;
}

// Animation for my site sections
@keyframes border {
    0% {
        border: 1px solid $white-1;
        border-radius: 0;
    }

    80% {
        border: 1px solid $blue-1;
        border-radius: $border-radius-1;
    }

    100% {
        border: 1px solid $white-1;
        border-radius: $border-radius-1;
    }
}

@keyframes skeleton {
    to {
        background-position-x: -200%;
    }
}
