@import '../../variables';

.margin {
    margin: 10px 0;
    margin-bottom: 20px;
}

.textCenter {
    text-align: center;
}

.textMuted {
    color: $border-tertiary-color;
}

.upload {
    width: 100%;
    padding: 30px 10px;
    cursor: pointer;
    text-align: center;
    background: rgba(0,0,0,.02);
    border: 1px dashed $background-other;
    border-radius: $border-radius-1;

    &:hover {
        border: 1px dashed $bg-secondary-color;
        transition: border 2s;
    }

    p {
        margin-bottom: 0;
        text-align: center;
    }
}

.uploadIcon {
    color: $bg-secondary-color;
    font-size: 4em;
    margin-bottom: 20px;
}

.optionsWrapper {
    display: flex;
    flex-direction: column;
    margin: 3px 0;
}
