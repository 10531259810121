.notificationContainer, .notificationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notificationContainer {
    position: absolute;
    z-index: 1010;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(0,0,0,0.8);
    color: white;
}

.notificationControlImage {
    height: 250px;
    width: 250px;
}

.notificationTextWrapper {
    max-width: 450px;
    min-width: 450px;
    text-align: justify;
}

.notificationTextWrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1.3em;
    }
}

.notificationMessageWrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    b {
        margin: 0;
        color: white;
    }

    h1 { font-size: 25px;}
    h2 { font-size: 23px;}
    h3 { font-size: 21px;}
    h4 { font-size: 19px;}
    h5 { font-size: 17px;}
    h6 { font-size: 15px;}
    p { font-size: 13px;}

    img {
        max-width: 100%;
    }
}

.button {
    border: none;
    padding: 5px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    width: 130px;
    height: 45px;
    cursor: pointer;
}

.buttonPrimary {
    @extend .button;
    color: #f9f9f9;
    background: #1f9fff;
}

.buttonSecondary {
    @extend .button;
    color: #1f9fff;
    border: 1px solid #1f9fff;
    background-color: transparent;
}

.buttonTertiary {
    @extend .button;
    margin: 0;
    padding: 0;
    color: #1f9fff;
    width: auto;
    background-color: transparent;
}

.closeButton {
    position: absolute;
    top: 1em;
    right: 1em;
    border: none;
    background-color: transparent;
}

.closeIcon {
    color: #f9f9f9;
    font-size: 2em;
}

.toast {
    overflow: hidden;
    color: #f9f9f9;
    background-color: #030C37;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-image: url('../../assets/menuBackgroundShort.jpg');

    strong {
        font-size: 15px;
    }
}

.toastHead {
    background-color: transparent;
}
