@import "../../../Common.module.scss";
@import "../../../variables";
@import "../../../breakpoints";

.subscriptionCard {
    border-radius: $border-radius-1 !important;
    box-shadow: $shadow-2;
    border: 1px solid $neutral-1 !important;
    padding: 0px !important;
    transition: 500ms all;
    cursor: pointer;
    margin: 25px 0px;
    max-height: 70px;

    &:hover, &.active {
        border-color: $bg-secondary-color !important;
        border-width: 1px !important;
        box-shadow: 0 0 8px 0 $bg-secondary-color;
    }

    .container {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 90px !important;
        min-width: 90px !important;
        max-width: 90px !important;
        margin-right: 20px;
        object-fit: contain;

        img {
            width: 100%;
        }

        .image {
            max-height: 30px;
            object-fit: contain;
            max-width: 90px;
        }
    }

    .content {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-height: 38px;
        width: 100%;
        overflow: hidden;

        .title {
            position: relative;
            top: -4px;
            font-size: 18px;
            min-width: 180px;
            max-width: 180px;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-decoration: none;
        }

        .result {
            color: $neutral-2;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            text-overflow: ellipsis;

            b {
                color: black;
                font-weight: 500;
                font-size: 12px;
            }

            .subscriptionResult {
                display: inline-flex;
                width: calc(100% - 82px);
                align-items: center;
                justify-content: space-between;
            }
        }

        .contentElement {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            * {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &:not(:first-child) {
                border-left: 1px solid $neutral-3;
                padding-left: 10px;
                margin-left: 10px;
            }
        }

        & p {
            min-width: 200px;
            max-width: 200px;
        }
    }

    .circleActive {
        position: relative;
        width: 10px;
        min-width: 10px;
        height: 10px;
        min-height: 10px;
        background-color: $neutral-3;
        display: inline-block;
        margin-left: 5px;
        border-radius: 100px;
        
        &.off {
            background-color: $magenta-900 !important;
        }
        &.on {
            background-color: $green-900 !important;
        }
    }
}
